@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
}
.border-hover {
  border-top: 0px #32864e solid;
  margin-top: 6px;
  transition: all 0.2s ease-in-out;
  height: 40px;
}
.border-hover.active {
  margin-top: 0px;
  border-top: 6px #32864e solid;
}
.border-hover:hover {
  margin-top: 0px;
  border-top: 5px #32864e solid;
}
::-webkit-scrollbar {
  width: 14px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: black; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #a7a5a5; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #242323; /* creates padding around scroll thumb */
}
::-webkit-scrollbar-corner {
  background-color: black;
}
:focus {
  outline: none;
}

select {
  background-color: #101010;
  border: thin solid #222222;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #a0a0a0;
  background-image: linear-gradient(45deg, transparent 70%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select:focus {
  background-image: linear-gradient(45deg, #f0f0f0 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #f0f0f0 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: #999999;
  outline: 0;
}

select option {
  padding: 3px;
  margin: 3px;
}
.grayscale {
  filter: grayscale(100%);
}
.h-full-minus {
  height: calc(100vh - 120px);
}
.surround-parenthesis::before {
  content: "(";
}
.surround-parenthesis::after {
  content: ")";
}
.surround-parenthesis:empty::before {
  content: "";
}
.surround-parenthesis:empty::after {
  content: "";
}
input {
  background-color: black !important;
}
